.lw {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.lw > .lt {
  flex: 1;
  background-color: var(--gte-primary-color);
}
.lw > .rt {
  width: 480px;
  overflow-y: scroll;
}
@media (max-width: 767px) {
  .lw > .lt {
    display: none;
  }
  .lw > .right {
    width: rt%;
  }
}
@media (min-width: 1366px) {
  .lw > .rt {
    width: 600px;
  }
}
.lw > .lt > iframe {
    width: calc(100% - 2px);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}
.lw > .lt > iframe > html {
  display: none;
}
.lw > .lt > iframe html body::-webkit-scrollbar-track,
.lw > .rt::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.lw > .lt > iframe html body::-webkit-scrollbar,
.lw > .rt::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.lw > .lt > iframe html body::-webkit-scrollbar-thumb,
.lw > .rt::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.lf {
  width: 400px;
  margin: 0 auto;
  margin-top: 50px;
  border: 0px solid var(--generic-border-color);
  padding: 0px;
}
.lf > h5 {
  color: var(--gte-primary-color);
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin-bottom: 30px;
}
.lf > h5 > img {
  height: 60px;
  margin-right: 10px;
}
.lf > p {
  font-size: 20px;
  text-align: center;
}
.lf > form {
  b1ackground-color: var(--login-form-bg-color);
  margin-top: 20px;
  border-radius: 5px;
  padding: 0px;
}
.lf > form > .fr {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
}
.lf > form > .fr > label {
  text-align: left;
  margin-bottom: 1px;
  font-size: var(--font-size-primary);
  font-weight: var(--font-weight-bold);
  line-height: 1.82;
}
.lf > form > .fr > input {
  padding: 10px 6px;
  text-align: left;
  border: 1px solid var(--generic-border-color);
  font-size: var(--font-size-primary);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  resize: none;
}
.lf > form > .fr > input:focus-visible {
  outline: 1px solid var(--gte-primary-color);
}
.lf > form > .fr > .hlp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
}
.lf > form > .fr > .hlp.err {
  justify-content: space-between;
}
.lf > form > .fr > .hlp > span.err {
  color: var(--form-error-color);
  font-size: var(--font-size-sub-primary);
  font-weight: var(--font-weight-bold);
  margin-top: 3px;
}
.lf > form > .fr > .hlp > a.fp {
  float: right;
  text-align: right;
  font-size: 12px;
}

.lf > form > .le {
  text-align: center;
  color: var(--generic-error-color);
  font-weight: var(--font-weight-bold);
}

.lf > form > .btns {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.lf > form > .btns > .login {
  background-color: var(--btn-color);
  color: #FFFFFF;
  width: 150px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
}
.lf > form > .loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.lf > form > .loader > span > i {
  font-size: 30px;
}

.lf > .pp {
  margin-top: 50px;
}
.lf > .pp > p {
  font-size: 12px;
  margin-bottom: 5px;
  color: #6d6c6c;
}
.lf > .pp > p > a {
  font-size: 12px;
}
