.infPg > .tpBr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.82);
  background-color: #f9f9f9;
  padding: 0 12px;
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.2);
  height: 60px;
}
.infPg > .tpBr > .lt {
  b1ackground-color: orange;
  padding: 4px 6px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.infPg > .tpBr > .lt:hover {
  background-color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.infPg > .tpBr > .lt > img {
  width: 12px;
}

.infPg > .tpBr > .rt {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.infPg > .tpBr > .rt > h1 {
  /* font-family: SF Pro Text; */
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0px;
  text-align: center;
  color: #1f0f04;
}

.infPg > .bdCnt {
  padding: 16px;
  height: calc(100% - 60px - 32px);
  height: calc(100% - 60px);
  overflow-y: scroll;
}
.infPg > .bdCnt::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.infPg > .bdCnt::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.infPg > .bdCnt::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.infPg > .bdCnt > .dgPgSecBx {
  margin-bottom: 10px;
}
.infPg > .bdCnt > .dgPgSecBx.image > img {
  width: 100%;
}
.infPg > .bdCnt > .dgPgSecBx.text > .inr {
  display: flex;
  flex-direction: column;
}
.infPg > .bdCnt > .dgPgSecBx.text > .inr > h3 {
  margin: 10px 0;
}
.infPg > .bdCnt > .dgPgSecBx.text > .inr > p {
  margin-bottom: 10px;
}
