.convBx {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;

  overflow-y: scroll;
}
.convBx::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.convBx::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.convBx::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.convBx > .msgEtry {
  padding: 10px;
  display: flex;
  flex-direction: row;
}
.convBx > .msgEtry.snt {
  justify-content: flex-end;
}
.convBx > .msgEtry.rec {
  justify-content: flex-start;
}
.convBx > .msgEtry > .msgBox {
}
.convBx > .msgEtry.rec > .msgBox {
  max-width: 80%;

}
.convBx > .msgEtry.snt > .msgBox {
  max-width: 90%;
}
.convBx > .msgEtry > .msgBox > .msg {
  border-radius: var(--generic-border-radius);
}
.convBx > .msgEtry.rec > .msgBox > .msg {
  background-color: var(--message-received-bg-color);
  border-bottom-left-radius: 0;
}
.convBx > .msgEtry.snt > .msgBox > .msg {
  background-color: var(--message-sent-bg-color);
  border-bottom-right-radius: 0;
}
.convBx > .msgEtry > .msgBox > .msg {
  border-radius: var(--generic-border-radius);
  padding: 10px;
  margin-bottom: 3px;
  font-size: var(--font-size-sub-mid-primary);
}
.convBx > .msgEtry > .msgBox > .msgSts {
  font-size: var(--font-size-sub-primary);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.convBx > .msgEtry > .msgBox > .msgSts > i {
  margin-left: 5px;
  margin-top: 2px;
}
