.bb {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #FFFFFF;
  border-top: solid 0.5px rgba(0, 0, 0, 0.3);
}
.bb > .inr {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.bb > .inr > a {
  height: 100%;
  padding: 8px 12px;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
}
.bb > .inr > a.active {
  background-color: #ea7d362e;
}
.bb > .inr > a > img {
  height: 20px;
}
.bb > .inr > a > i {
  margin-right: 5px;
  color: #929BB4;
}
.bb > .inr > a.active > i {
  margin-right: 5px;
  color: var(--gte-primary-color);
}
.bb > .inr > a > span {
  padding: 1px 7px;
  border-radius: 20px;
  background-color: #929BB4;
  position: absolute;
  top: 2px;
  right: 2px;
  color: #FFFFFF;
  font-size: var(--font-size-sub-primary);
}
.bb > .inr > a.active > span {
  background-color: var(--gte-primary-color);
}
