.wrp {
  h1eight: 600px;
  m1ax-height: 600px;
  height: 100%;
  background-color: #FFFFFF;
  m1ax-width: 360px;
  margin: 0 auto;
  position: relative;
}
.wrp > .tb {
  height: 60px;
}
.wrp > .mb {
  border-left: 1px solid var(--generic-border-color);
  border-right: 1px solid var(--generic-border-color);
}
.wrp > .mb.wlc {
  height: calc(600px - 60px);
  height: calc(100% - 60px - 60px);
}
.wrp > .mb.gde {
  height: calc(600px - 60px - 60px);
  height: calc(100% - 60px - 60px);
}
.wrp > .mb.eng {
  height: calc(600px - 60px - 60px);
  height: calc(100% - 60px - 60px);
}
.wrp > .bb {
  height: 60px;
}


.wrp > .mb.gde.htlInf {
  height: calc(100% - 60px);
}
.wrp > .mb.gde.infPg {
  height: calc(100%);
}




@media (min-width: 768px) {
  .wrp {
    max-width: 360px;
  }
}
