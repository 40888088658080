.sndBx {
  height: 70px;
  background-color: #f9f9f9;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sndBx > .bd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}
.sndBx > .bd > .lt {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-right: 10;
  gap: 10px;
}
.sndBx > .bd > .rt {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.sndBx > .bd > .lt.hasMsg {
  padding-right: 0px;
}
.sndBx > .bd > .lt > .opts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sndBx > .bd > .lt > textarea {
  width: 100%;
  resize: none;
  padding: 5px;
  border-radius: var(--generic-border-radius);
}
.sndBx > .bd > .rt > .sndBtn {
  padding: 5px 10px;
  border-radius: var(--generic-border-radius);
  background-color: #ea7d36;
  font-size: 20px;
  color: #FFFFFF;
}


/* ************** dropdown menu - Emoji Selector ********************** */

.opIt > .dd {
  position: relative;
  display: inline-block;
  margin-top: -1px;
}

.opIt > .dd > .dbtn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.opIt > .dd > .dbtn > img {
  padding: 3px;
  border-radius: 3px;
}
.opIt > .dd > .dbtn > img.gbm {
  height: 23px;
}
.opIt > .dd > .dbtn > img:hover {
  background-color: #FFFFFF;
}
.opIt > .dd > .dbtn > img.gbm:hover {
  background-color: #FFFFFF;
}
.opIt > .dd > .dbtn > i {
  padding: 3px 4px;
  border-radius: 3px;
}
.opIt > .dd > .dbtn > i:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}

.opIt > .dd > .ddc {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-height: 300px;
}
.opIt > .dd > .ddc {
  left: 0px;
  top: -458px;
}
@media (max-width: 360px) {
  .opIt > .dd > .ddc {
    left: -10px;
  }
}

.opIt > .ddc p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.opIt > .ddc p:hover {background-color: #ddd; cursor: default;}

.opIt > .dd.open > .ddc {display: block;}

.opIt .emojiBox .emoji-mart {
  overflow-y: hidden;
  width: 320px!important;
  height: 300px!important;
}

/* ************************************ */
