.hmPg {
  background-color: #f9f9f9;
}
.hmPg > .bdCnt {
  display: flex;
  flex-direction: column;
}
.hmPg > .bdCnt > a {
  margin: 16px 16px 0 16px;
  padding: 16px;
  border-radius: var(--generic-border-radius);
  text-decoration: none;
  background-color: #FFFFFF;
}
.hmPg > .bdCnt > a:hover {
  background-color: var(--generic-hover-bg-color);
}
.hmPg > .bdCnt > a > .sectBx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hmPg > .bdCnt > a > .sectBx > .lt {
  width: 40px;
}
.hmPg > .bdCnt > a > .sectBx > .rt {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hmPg > .bdCnt > a > .sectBx > .rt > .lblRw {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
.hmPg > .bdCnt > a > .sectBx > .rt > .lblRw > .lt {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.01px;
  text-align: left;
  color: var(--primary-text-color);
}
.hmPg > .bdCnt > a > .sectBx > .rt > .descRw {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: left;
  color: var(--primary-text-color-light);
}
