.htlInf > .tpBr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
  height: 60px;
}
.htlInf > .tpBr > .lt {
  b1ackground-color: orange;
  padding: 4px 6px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.htlInf > .tpBr > .lt:hover {
  background-color: #FFFFFF;
  border-radius: var(--generic-border-radius);
}
.htlInf > .tpBr > .lt > img {
  width: 12px;
}
.htlInf > .tpBr > .rt {
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.htlInf > .tpBr > .rt > h1 {
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0px;
  text-align: center;
  color: #1f0f04;
}

.htlInf > .bdCnt {
  display: flex;
  flex-direction: column;
}
.htlInf > .bdCnt > a {
  padding: 16px 16px 0 16px;
  text-decoration: none;
}
.htlInf > .bdCnt > a:hover {
  background-color: var(--generic-hover-bg-color);
}
.htlInf > .bdCnt > a > .sectBx {
  padding: 0 0 16px 0;
  border-bottom: solid 1px rgba(163, 157, 153, 0.24);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.htlInf > .bdCnt > a > .sectBx > .lt {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  text-align: left;
  color: var(--primary-text-color);
}
.htlInf > .bdCnt > a > .sectBx > .rt > img {
  width: 22px;
}

/* room info */

.htlInf.rmInf > .bdCnt {
  padding: 16px;
  height: calc(100% - 60px);
  overflow-y: scroll;
}
.htlInf.rmInf > .bdCnt::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.htlInf.rmInf > .bdCnt::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.htlInf.rmInf > .bdCnt::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}

.styBx {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.styBx > .tp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}
.styBx > .bt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.styBx > .bt > .etry {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.styBx > .bt > .etry > .val {
  font-weight: var(--font-weight-bold);
}
.styBx > .tp > .etry {
  display: flex;
  flex-direction: column;
  align-items: center;
}
