.mb.eng {
  border-left: 1px solid var(--generic-border-color);
  border-right: 1px solid var(--generic-border-color);
  background-color: #FFFFFF;
}
.enWrp {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.enWrp > .stInfBx {
  background-color: #675c55;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-sub-mid-primary);
  padding: 6px;
}
.enWrp > .stInfBx > .etry {
  display: flex;
  flex-direction: column;
  align-items: center;
}
