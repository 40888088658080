.tb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.82);
  background-color: #f9f9f9;
  padding: 0 16px;
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.2);
}
.tb > .lt {
  width: calc(100% - 80px);
}
.tb > .rt {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.tb > .lt > .title {
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.tb > .lt > .title  > img {
  height: 30px;
}


/* ************** menu - dropdown menu ********************** */

.giActions > .dropbtn {
  background-color: #4CAF50;
  background-color: #FFFFFF;
  color: #AAA;
  1padding: 10px;
  font-size: 16px;
  border: none;
}

.tb > .rt > .dropdown {
  position: relative;
  display: inline-block;
  margin-top: 0px;
}

.tb > .rt > .dropdown > .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.tb > .rt > .dropdown > .dropdown-content {
  left: -85px;
}

.tb > .rt > .dropdown > .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.tb > .rt > .dropdown > .dropdown-content a:hover {background-color: var(--generic-hover-bg-color);}

.tb > .rt > .dropdown.menuBox.open .dropdown-content {display: block;}

.tb > .rt > .dropdown.menuBox > .dropbtn {
  padding: 10px;
  background-color: #FFFFFF;
  cursor: pointer;
  1border-radius: 20px 16px;
}
.tb > .rt > .dropdown.menuBox > .dropbtn:hover {
  background-color: var(--generic-hover-bg-color);

}

/* ************************************ */
