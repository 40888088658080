.lgOptWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 50px;
}
.lgOptWrap > .ttl {
  font-size: var(--font-size-title);
  margin-bottom: 12px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  color: var(--primary-text-color);
}
.lgOptWrap > .subTtl {
  font-size: var(--font-size-primary);
  margin-bottom: 24px;
  text-align: center;
  color: var(--primary-text-color-light);
}
.lgOptWrap > .lgOpts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.lgOptWrap > .lgOpts > a {
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: var(--generic-border-radius);
  border: 1px solid var(--generic-border-color);
  text-decoration: none;
  background-color: #f9f9f9;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
}

.lgOptWrap.bookingId {
  align-items: flex-start;
}
.lgOptWrap > form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}
.lgOptWrap > form > .fr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  width: 100%;
}
.lgOptWrap > form > .fr > label {
  margin-bottom: 8px;
}
.lgOptWrap > form > .fr > label > span {
  margin-left: 6px;
  color: var(--primary-text-color-light);
}
.lgOptWrap > form > .fr > input {
  border-radius: var(--generic-border-radius);
  border: solid 1px rgba(37, 39, 52, 0.1);
  padding: 10px 13px;
  width: 100%;
}
.lgOptWrap > form > .fr > .err {
  font-size: var(--font-size-sub-primary);
  color: var(--generic-error-color);
  font-weight: var(--font-weight-bold);
  margin-top: 5px;
  margin-bottom: 5px;
}
.lgOptWrap > form > .fr > .hlpMsg {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 3px;
  font-size: var(--font-size-sub-primary);
}
.lgOptWrap > form > .fr > .hlpMsg > img {
  height: 16px;
}
.lgOptWrap > form > .btns {
  margin-top: 20px;
}
.lgOptWrap > form > .btns > button {
  padding: 10px 13px;
  border-radius: var(--generic-border-radius);
  width: 100%;
  background-color: var(--bg-engage-dark);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.lgOptWrap > form > .btns > button.active {
  background-color: #1f0f04;
  color: #FFFFFF;
}
.lgOptWrap > form > .err {
  margin-top: 10px;
  text-align: center;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub-mid-primary);
}

.lgOptWrap > .otherOpts {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lgOptWrap > .otherOpts > a {
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sub-primary);
}
